import AllRoutes from './AllRoutes/AllRoutes';

function App() {
  return (
    <>
      <AllRoutes />
    </>
  );
}

export default App;
